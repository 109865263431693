//
// ProjectCard.tsx
//

import * as Headless from "@headlessui/react";
import AddCircleIcon from "@layouts/svg-icon/add-circle-icon.svg";
import CreateProjectBackground from "@layouts/svg-illustration/create-project.svg";
import clsx from "clsx";

/**
 * CreateProjectCard Component
 *
 * This component renders a styled button card for adding a new project.
 * It uses Headless UI's Button component with additional styling and properties
 * passed from CreateProjectCardProps.
 *
 * @param {CreateProjectCardProps} props - The component's props.
 * @param {string} [props.width="tw-w-[36rem]"] - Optional width for the button card, defaults to "tw-w-[36rem]".
 * @returns {JSX.Element} The rendered CreateProjectCard component.
 */
export type CreateProjectCardProps = {
  width?: string;
} & Omit<Headless.ButtonProps, "as" | "className">;

export function CreateProjectCard({
  width = "tw-w-[36rem]",
  ...props
}: CreateProjectCardProps) {
  // Render component
  return (
    <Headless.Button
      {...props}
      className={clsx(
        // Base
        "tw-relative tw-overflow-hidden",
        // Size
        width,
        "tw-h-52",
        // Base
        "tw-rounded-2xl tw-border tw-border-transparent focus:tw-outline-none data-[focus]:tw-outline-1 data-[focus]:tw-outline-offset-2",
        // Background
        "tw-bg-interface-innerCard-light dark:tw-bg-interface-innerCard-dark",
        // Hover
        "tw-dark:hover:tw-border-interface-gray-light hover:tw-border-interface-gray-light",
        // Focus
        "tw-dark:data-[focus]:tw-outline-brandBlues-brandDark-dark data-[focus]:tw-outline-brandBlues-brandDark-light",
        // Transition
        "tw-transition-border tw-duration-200 tw-ease-in-out"
      )}
    >
      <CreateProjectBackground
        width="100%"
        className="tw-pointer-events-none tw-absolute tw-bottom-0"
      />

      <div className={"tw-relative tw-h-full tw-w-full"}>
        <div className="tw-flex tw-h-full tw-w-full tw-flex-col tw-items-center tw-justify-center tw-gap-y-4 tw-overflow-hidden">
          <AddCircleIcon />
          <div className="tw-flex tw-flex-col tw-gap-y-1">
            <div className="tw-dark:tw-text-text-title-dark tw-text-16px-semiBold tw-text-text-title-light">
              New Project
            </div>
            <div className="tw-dark:tw-text-text-subTitle-dark -tw-mt-1 tw-text-14px-regular tw-text-text-subTitle-light">
              Create a new database service.
            </div>
          </div>
        </div>
      </div>
    </Headless.Button>
  );
}
