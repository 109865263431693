import { useViewSubscription } from "@custom-hooks/billing";
import { SubscriptionStatusAlertProps } from "@data-types/billing-types";
import { ContactDialog } from "@tw-components/OrgHome/ContactDialog";
import { Alerts, MessageType } from "@tw-components/ui/alerts";
import { GenericDialog } from "@tw-components/ui/dialog";
import { useState } from "react";

type SubscriptionStatusDialog = SubscriptionStatusAlertProps & {
  isOpen: boolean;
  onClose: () => void;
};

export function SubscriptionStatusDialog({
  title,
  description,
  actionButtonText,
  isOpen,
  onClose,
}: SubscriptionStatusDialog): JSX.Element {
  const [showContactDialog, setShowContactDialog] = useState(false);
  const { viewSubscription, isLoading: isLoadingViewSubscription } =
    useViewSubscription({});

  // TODO:DAMLA - handle view errors

  return (
    <>
      <GenericDialog
        onClose={onClose}
        open={isOpen}
        title={title}
        body={
          <Alerts
            messages={[{ type: MessageType.Error, title: description }]}
          />
        }
        submitButtonProps={{
          variant: "primary",
          label: actionButtonText,
          showLoader: isLoadingViewSubscription,
          className: "tw-w-52",
          onClick: viewSubscription,
        }}
        secondaryButtonProps={{
          text: "Contact us",
          onClick: () => {
            onClose();
            setShowContactDialog(true);
          },
        }}
        size="xl"
      />

      <ContactDialog
        isOpen={showContactDialog}
        onClose={() => setShowContactDialog(false)}
      />
    </>
  );
}
