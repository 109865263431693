//
// DeleteProjectDialog.tsx
//

import {
  useCancelSubscription,
  useGetUserPlan,
  useUpdateSubscription,
} from "@custom-hooks/billing";
import { useGetUserProjects } from "@custom-hooks/projects";
import { useDeleteProject } from "@custom-hooks/projects/hooks/useDeleteProject";
import {
  AddonActionNames,
  AddonLookupKey,
  PlanLookupKey,
} from "@data-types/billing-types";
import { Project } from "@data-types/projects-types";
import WarningIcon from "@layouts/svg-icon/warning-triangle-icon.svg";
import { GenericDialog } from "@tw-components/ui/dialog";
import { GenericInput } from "@tw-components/ui/input";
import { useState } from "react";

const UPGRADING_INFRA = process.env.NEXT_PUBLIC_UPGRADING_INFRA;

type DeleteProjectDialogProps = {
  project: Pick<Project, "env" | "id" | "name">;
  isOpen: boolean;
  onClose: () => void;
};

export function DeleteProjectDialog({
  project,
  isOpen,
  onClose,
}: DeleteProjectDialogProps): JSX.Element {
  const confirmString = "delete my project";

  const [projectName, setProjectName] = useState<string>("");
  const [deleteConfirmation, setDeleteConfirmation] = useState<string>("");

  const { deleteProject, isDeletingProject } = useDeleteProject({
    mutatedCallback: onClose,
    errorCallback: () => {
      // TODO:DAMLA - show alert inside
    },
  });
  const { data: projects } = useGetUserProjects();
  const plan = useGetUserPlan();

  const { updateSubscription, isLoading: isLoadingUpdateSubscription } =
    useUpdateSubscription({
      mutatedCallback: onClose,
      errorCallback: () => {
        // TODO:DAMLA - show alert inside
      },
    });

  const { cancelSubscription, isLoading: isLoadingCancelSubscription } =
    useCancelSubscription({
      mutatedCallback: onClose,
      errorCallback: () => {
        // TODO:DAMLA - show alert inside
      },
    });

  const isProUserDeletingLastProject =
    projects?.length === 1 && plan === PlanLookupKey.PRO_PLAN_MONTHLY;

  const showLoader =
    isDeletingProject ||
    isLoadingUpdateSubscription ||
    isLoadingCancelSubscription;

  const handleDelete = async () => {
    if (UPGRADING_INFRA && UPGRADING_INFRA.toLowerCase() === "true") {
      alert(
        "We're upgrading our infrastructure to serve you better! Deleting a project is temporarily disabled."
      );

      return;
    }

    switch (plan) {
      case PlanLookupKey.PRO_PLAN_MONTHLY:
        if (isProUserDeletingLastProject) {
          await cancelSubscription();
        } else {
          await updateSubscription({
            newAddons: [
              {
                addon: AddonLookupKey.PRO_PROJECT_MONTHLY,
                quantity: -1,
                action: [
                  {
                    name: AddonActionNames.DELETE,
                    data: {
                      projectId: project.id,
                    },
                  },
                ],
              },
            ],
          });
        }
        break;
      case PlanLookupKey.SANDBOX:
        await deleteProject(project.id);
        break;
      default:
        // TODO:DAMLA
        break;
    }
  };

  return (
    <GenericDialog
      onClose={onClose}
      open={isOpen}
      title="Delete Project"
      description={`Are you sure you want to delete ${project.name}?`}
      body={
        <div className="tw-flex tw-flex-col tw-gap-4">
          <GenericInput
            sizeVar="medium"
            label={`To verify, type “${project.name}” below to remove this project from your organization`}
            value={projectName}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setProjectName(e.target.value);
            }}
            disabled={showLoader}
          />

          <GenericInput
            sizeVar="medium"
            label={`To verify, type “${confirmString}” below`}
            value={deleteConfirmation}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setDeleteConfirmation(e.target.value);
            }}
            disabled={showLoader}
          />

          {isProUserDeletingLastProject && (
            <div className="tw-flex tw-flex-row tw-gap-2">
              <WarningIcon />
              <div className="tw-text-12px-regular tw-text-semantics-error-light dark:tw-text-semantics-error-dark">
                Your workspace will be downgraded to a Sandbox plan.
              </div>
            </div>
          )}
        </div>
      }
      submitButtonProps={{
        size: "medium",
        variant: "destructive",
        label: "Delete",
        onClick: handleDelete,
        className: "tw-w-[6.625rem]",
        disabled:
          project.name !== projectName || confirmString !== deleteConfirmation,
        showLoader: showLoader,
      }}
      secondaryButtonProps={{ text: "Cancel", onClick: onClose }}
      size="3xl"
      buttonLayoutType="row"
    />
  );
}
