"use client";

import clsx from "clsx";
import React from "react";

export function TooltipLabel({
  className,
  ...props
}: React.ComponentPropsWithoutRef<"span">) {
  return (
    <span
      {...props}
      className={clsx(
        className,
        // "tw-truncate",
        "tw-absolute",
        "tw-mr-4 tw-rounded-3xl tw-border tw-px-3 tw-py-1",
        "tw-text-12px-regular",
        "tw-border-brandBlues-ice-light tw-bg-brandBlues-pale-light tw-text-brandBlues-brandDark-light",
        "tw-dark:tw-bg-brandBlues-pale-dark tw-dark:tw-text-brandBlues-brandDark-dark tw-dark:tw-border-brandBlues-ice-dark"
      )}
    />
  );
}
